import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/pages/Home.vue"

Vue.use(VueRouter)

export enum RouteName {
    home = 'home',
}

const routes = [
    {
        path: '/',
        name: RouteName.home,
        component: Home
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router
