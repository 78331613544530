





























































































































































































































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Footer from "@/components/Footer.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SimpleNavbar from "@/components/SimpleNavbar.vue"

@Component({
    components: {SimpleNavbar, Row, SpacerBox, Container, Footer, TextField, Column}
})
export default class Home extends Vue {

    mirandaLogo = require('@/assets/img/miranda_text_242.png')

    playstore = require('@/assets/img/google_play_badge.png')
    mirandaQr = require('@/assets/img/google_play_qr.png')

    screenshot1 = require('@/assets/img/screenshot1.png')
    screenshot2 = require('@/assets/img/screenshot2.png')
    screenshot3 = require('@/assets/img/screenshot3.png')
    screenshot4 = require('@/assets/img/screenshot4.png')


}
