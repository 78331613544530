









































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Link from "@/components/fundamental/text/Link.vue"
import {RouteName} from "@/router"

@Component({
    components: {Link, SpacerBox, Row, Column}
})
export default class SimpleNavbar extends Vue {

    mirandaLogo = require('@/assets/img/miranda_text_142.png')

    routeNames = RouteName
}
